import { MyButton, Card, Thumbnail, Icon } from './components'
import * as imgs from './img'
export default function Home() {
    const bgImg = {
        backgroundImage: 'url(' + imgs.bg + ')',
    }
    return (
        <>
            <div id='home' className='topBanner anchor' style={bgImg}>
                <div className='cHeading'>
                    <h1>Études Développement Maroc</h1>
                    <h2>Bureau d'études techniques</h2>
                    <MyButton isMinimal='scroll' title='Nos Services' type={1} icon='chevron-down' link='#services' />
                </div>
            </div>
            <div className='sectionTitle'>
                <p>QUALITÉ</p>
                <h1>Nos Services</h1>
            </div>
            <div className='services' >
                <div className='anchor' id='services' />
                <div className='servicesRow'>
                    <Card
                        img={imgs.icon1}
                        title='Études de faisabilité'
                        desc='Transformez vos idées en réalité grâce à notre expertise en études de faisabilité'
                    />
                    <Card
                        img={imgs.icon2}
                        title='Études des plans architectes'
                        desc='Créez des espaces innovants et fonctionnels avec notre équipe de concepteurs'
                    />
                    <Card
                        img={imgs.icon3}
                        title='Suivi de chantier'
                        desc='Supervision de chantier : Garantie de qualité et de conformité.'
                    />
                </div>
                <div className='servicesRow'>
                    <Card
                        img={imgs.icon4}
                        title='Ingénierie structurelle'
                        desc='Renforcez la solidité de vos constructions grâce à notre expertise en ingénierie structurelle'
                    />
                    <Card
                        img={imgs.icon5}
                        title='Gestion de projet'
                        desc='Gestion professionnelle de projets : Respect des échéances et des budgets garanti'
                    />
                    <Card
                        img={imgs.icon6}
                        title='Études environnementales'
                        desc="Protégez l'environnement et respectez les réglementations grâce à nos études spécialisées"
                    />
                </div>
            </div>
            <h3 className='quote1'>Selon vos conceptions architecturales, notre Bureau d'Études Techniques EDM serait un portail des solutions innovantes et adaptées à vos projets.</h3>
            <div className='list1'>
                <ul>
                    <li>Résidentiels et Habitations</li>
                    <li>Industriels et Halls </li>
                    <li>Villas et Piscines</li>
                    <li>Maisons individuelles</li>
                    <li>Aménagement des locaux</li>
                    <li>Équipements publiques</li>
                    <li>Hôpitaux, Hôtels, Écoles et Amphithéâtres</li>
                    <li>Aménagement des espaces verts</li>
                    <li>Infrastructures générales</li>
                </ul>
                <img src={imgs.img6} alt=''/>
            </div>
            <div className='sectionTitle'>
                <p>CONFIANCE</p>
                <h1>L'Expertise</h1>
            </div>
            <div className='expertise' >
                <div className='anchor' id='expertise' />
                <Thumbnail
                    img={imgs.img1}
                    title='Gros Oeuvres'
                    desc="Notre entreprise de bureau d'études techniques, spécialisée dans les gros œuvres, excelle dans la conception, la planification et la coordination des éléments structurels essentiels d'un projet de construction. Nous combinons savoir-faire technique et expérience pratique pour garantir la solidité, la sécurité et la durabilité des structures, en utilisant des technologies avancées et des méthodologies innovantes. En collaboration étroite avec nos clients, nous nous engageons à fournir des solutions sur mesure répondant aux normes de qualité les plus strictes."
                />
                <Thumbnail
                    img={imgs.img2}
                    title='Béton Armé'
                    desc="Experts reconnus dans le domaine du béton armé, nous nous spécialisons dans la conception, la planification et la réalisation de structures durables et innovantes. Notre maîtrise des techniques de renforcement et des normes de sécurité garantit la solidité et la résilience des constructions. Travaillant en étroite collaboration avec nos clients, nous offrons des solutions sur mesure qui répondent aux exigences les plus strictes en matière de qualité et de conformité réglementaire. Que ce soit pour des projets résidentiels, commerciaux ou industriels, nous sommes fiers de contribuer au succès de chaque réalisation dans le domaine du béton armé."
                />
                <Thumbnail
                    img={imgs.img3}
                    title='Étanchéité'
                    desc="Dans le domaine complexe de l'étanchéité et du béton armé, notre expertise est reconnue. Notre expertise compte la conception et la réalisation de structures solides et résistantes, tout en mettant l'accent sur une protection efficace contre les infiltrations d'eau. Notre approche repose sur une connaissance approfondie des techniques d'étanchéité les plus avancées, nous permettant d'assurer une intégrité durable des constructions. En collaboration étroite avec nos clients, nous concevons des solutions sur mesure qui répondent précisément à leurs besoins, tout en respectant les normes les plus strictes en matière de qualité et de conformité. Que ce soit pour des projets résidentiels, commerciaux ou industriels, notre engagement envers l'excellence garantit le succès de chaque réalisation dans le domaine de l'étanchéité."
                />
                <Thumbnail
                    img={imgs.img4}
                    title='Électricité'
                    desc="En tant qu'entreprise de bureau d'études techniques spécialisée en électricité, notre expertise réside dans la conception, la planification et la mise en œuvre de systèmes électriques complexes pour diverses applications. Nous avons une connaissance approfondie des normes et réglementations en matière d'électricité, ce qui garantit la conformité aux exigences de sécurité et de performance les plus strictes. Notre équipe expérimentée offre des solutions innovantes et efficaces pour répondre aux besoins spécifiques de chaque client, tout en optimisant l'efficacité énergétique et en minimisant les coûts. En tant que partenaire de confiance, nous nous engageons à fournir des services de haute qualité, de la conception à la mise en service, pour assurer le succès à long terme de chaque projet électrique."
                />
                <Thumbnail
                    img={imgs.img7}
                    title='Fluides et Assainissement'
                    desc="En tant que spécialiste de l’ingénierie, nous offrons une gamme complète de services dans le domaine des fluides et de l’assainissement. Nos compétences couvrent la conception, la planification et la mise en œuvre de systèmes de gestion des eaux usées, ainsi que l’optimisation des systèmes de distribution d’eau et de gaz. Nous nous engageons à fournir des solutions durables et efficaces pour répondre aux besoins spécifiques de chaque projet. Notre équipe d’experts travaille en étroite collaboration avec nos clients pour garantir la satisfaction et le respect des normes environnementales. Notre objectif est de contribuer à la création d’un environnement sain et sûr pour tous."
                />
            </div>
            <div className="titles">
                <h1>Études structures & Béton Armé</h1>
                <h1>مكتب الدراسات الهندسية</h1>
                <h1>مكتب الهندسة للأفضل</h1>
            </div>
            <div className='sectionTitle'>
                <p>À VOTRE ÉCOUTE</p>
                <h1>Contact</h1>
            </div>
            <div className='contact'>
                <div className='anchor' id='contact'></div>
                <h1>Des questions ? Nous sommes là.</h1>
                <div className='screen'>
                    <div className='decor'>
                        <img src={imgs.img5} alt='' />
                    </div>
                    <div className='content'>
                        <a className='cRow' target='blank' href='https://wa.me/212662140802'>
                            <div className='iconN'>
                                <Icon type='whatsapp' brand={true} />
                            </div>
                            <h3>+2126.62.14.08.02</h3>
                        </a>
                        <a className='cRow' target='blank' href='https://maps.app.goo.gl/V8nZcQHDTwDkH8fk6'>
                            <div className='iconN x'>
                                <Icon type='location-dot' />
                            </div>
                            <h3>Résidence Al Majd, 3 Rue Henri Murger, Casablanca</h3>
                        </a>
                        <a className='cRow' href='tel:212661415937'>
                            <div className='iconN'>
                                <Icon type='phone' />
                            </div>
                            <h3>+2125.22.23.75.34</h3>
                        </a>
                        <a className='cRow' href='mailto:contact@edmingenierie.com'>
                            <div className='iconN'>
                                <Icon type='envelope' />
                            </div>
                            <h3>contact@edmingenierie.com</h3>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}