export default function Card({ img, title, desc }) {
    return (
        <div className='card'>
            <div className='content'>
                <div><img src={img} alt='' /></div>
                <div>
                    <h3>{title}</h3>
                </div>
                <div>
                    <p>{desc}</p>
                </div>
            </div>
            <div className='circle'></div>
        </div>
    )
}
