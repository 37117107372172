import { logo } from '../img'

export default function Footer() {
    return (
        <footer className='footer-distributed'>

            <div className='footer-center'>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-location-dot'></i></div>
                    <p>Val Fleuri, Casablanca, Maroc</p>
                </div>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-phone'></i></div>
                    <p><a href='tel:+212522237534'>+2125.22.23.75.34</a></p>
                </div>
                <div className='infoContainer'>
                    <div className='iconContainer'><i className='fa fa-envelope'></i></div>
                    <p><a href='mailto:contact@edmingenierie.com'>contact@edmingenierie.com</a></p>
                </div>
            </div>
            <div className='footer-left'>
                <img src={logo} alt='' />
                <p className='footer-links'>
                    <a href='/' className='link-1'>Home</a>
                    <a href='/courses'>Services</a>
                    <a href='/contact'>About Us</a>
                </p>
                <p className='footer-company-name'>EDM © 2024</p>
            </div>
            <div className='footer-right'>
                <p className='footer-company-about'>
                    <span>Études Développement Maroc</span>
                    Grâce à notre expérience, notre expertise et la diversité des projets réalisés, notre équipe analyse vos besoins et vous assure un conseil objectif.
                </p>
            </div>
        </footer >
    )
}
