export default function Icon({ brand, type, width, regular, color }) {
    if (brand) {
        return <i className={'fa-brands fa-' + type} style={{ fontSize: width, color }} />
    }
    if (regular) {
        return <i className={'fa-regular fa-' + type} style={{ fontSize: width, color }} />
    }
    return (
        <i className={'fa-solid fa-' + type} style={{ fontSize: width, color }} />
    )
}
