import { useEffect } from 'react';
import { Footer, Header } from './components';
import AOS from 'aos';
import './style/reset.css';
import './style/style.css';
import './style/aos.css';
import Home from './Home'
function App() {
	useEffect(() => {
		AOS.init({
			delay: 200,
			once: false,
			easing: 'ease-in-out',
		});
	  }, []);
    return (
		<>
			<Header />
				<Home />
			<Footer />
		</>	
    );
}

export default App;
