
export default function Thumbnail({ img, title, desc }) {
    return (
        <div className='thumbnail n'>
            <div className='img'>
                <img src={img} alt='' />
            </div>
            <div className='content'>
                <h1>{title}</h1>
                <div className='desc'>
                    <p>{desc}</p>
                </div>
            </div>
        </div>
    )
}