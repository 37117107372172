import React from 'react';
import { logo } from '../img';
export default function Header() {
    return (
        <nav className='navbar navbar-expand-lg navbar-dark'>
            <a className='navbar-brand' href='/'>
                <img src={logo} alt='logo' width='150' />
            </a>
            <button
                className='navbar-toggler'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#navbarNav'
                aria-controls='navbarToggleExternalContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
                style={{ outline: 'none' }}
            >
                <i className='fa-solid fa-bars'></i>
            </button>

            <div className='collapse navbar-collapse' id='navbarNav' style={{ justifyContent: 'flex-end' }}>
                <ul className='navbar-nav'>
                    <li className='nav-item'>
                        <a href='#home' className='nav-link'>
                            Acceuil
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#services' className='nav-link'>
                            Services
                        </a>
                    </li>
                    <li className='nav-item'>
                        <a href='#expertise' className='nav-link'>
                            Expertise
                        </a>
                    </li>
                    <li className='nav-item active'>
                        <a href='#contact' className='nav-link'>
                            Contact
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

